<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{ $t('order.subtitle') }}</v-card-title>
      <v-card-text>
        <v-simple-table fixed-header>
          <template #default>
            <thead>
              <tr>
                <th class="text-uppercase">
                  {{ $t('order.table.column.product') }}
                </th>
                <th class="text-center text-uppercase">
                  {{ $t('order.table.column.quantity') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in products" :key="product.id">
                <td>{{ product.name }}</td>
                <td class="text-center">
                  {{ product.quantity }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  export default {
    setup () {
      return {
        products: [
          {
            id: 1,
            name: 'Crêpe au chocolat Maison',
            quantity: 4
          },
          {
            id: 2,
            name: 'Vin rouge',
            quantity: 1
          }
        ]
      };
    }
  };
</script>
